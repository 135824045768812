.muur {
  &-h2 {
    &__wrapper {
      font-family: var(--font-secondary);
      font-size: 4.375rem;
      font-style: normal;
      line-height: 100%;

      @media screen and (max-width: 1024px){
        font-size: 3rem;
      }
      @media screen and (max-width: 768px){
        font-size: 2rem;
      }
      @media screen and (max-width: 560px){
        font-size: 1.5rem;
      }
    }

    font-weight: 600;
    border-radius: 7.5rem;
    padding: 0.9375rem 2.8125rem;
    width: fit-content;
    max-width: 100%;
    margin-top: 0.7rem;

    @media screen and (max-width: 768px){
      padding: 0.75rem 2rem;
    }
    &_sub {
      font-weight: 300;
      border-radius: 7.5rem;
      padding: 0.9375rem 2.8125rem;
      margin-top: 0.7rem;
      width: fit-content;
      max-width: 100%;
      @media screen and (max-width: 768px){
        padding: 0.75rem 2rem;
      }
    }
  }
  &-h3 {
    &__wrapper {
      font-family: var(--font-secondary);
      font-size: 3.82856rem;
      font-style: normal;
      line-height: 100%;

      @media screen and (max-width: 1270px){
        font-size: 2.5rem;
      }
      @media screen and (max-width: 768px){
        font-size: 1.8rem;
      }
      @media screen and (max-width: 560px){
        font-size: 1.35rem;
      }
    }

    font-weight: 600;
    border-radius: 6.56325rem;
    padding: 0.82038rem 2.46119rem;
    width: fit-content;
    max-width: 100%;
    margin-top: 0.7rem;
    @media screen and (max-width: 768px){
      padding: 0.6rem 2rem;
    }
    @media screen and (max-width: 560px){
      padding: 0.6rem 0.75rem;
    }
    &_sub {
      font-weight: 300;
      border-radius: 6.56325rem;
      padding: 0.82038rem 2.46119rem;
      margin-top: 0.7rem;
      width: fit-content;
      max-width: 100%;
      hyphens: auto;
      @media screen and (max-width: 768px){
        padding: 0.6rem 2rem;
      }
      @media screen and (max-width: 560px){
        padding: 0.6rem 0.75rem;
      }
    }
  }
  &-text_title{
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  &-list{
    list-style: disc;
    margin-left: 2rem;

    li{
      padding: 0.25rem 0
    }
  }
  &-h1{
    font-family: var(--font-secondary);
    font-size: 4.375rem;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 4.3rem;

    @media screen and (max-width: 768px){
      font-size: 3rem;
      margin-bottom: 3rem;
    }
    @media screen and (max-width: 560px){
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
  }
  &-description{
    font-family: var(--font-primary);
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    word-break: break-word;

    @media screen and (max-width: 768px){
      font-size: 1.25rem;
      line-height: 1.5;
    }
    a{
      text-decoration-line: underline;
      color: var(--color-link);
      transition: 0.3s ease;

      &:hover{
        text-decoration: none;
      }
    }
    ol {
      list-style: decimal;
      margin: {
        left: 3rem;
        top: 1rem;
        bottom: 1rem;
      }
      @media screen and (max-width: 768px){
        margin: {
          left: 2rem;
        }
      }
      li{
        padding: 0.25rem 0
      }
    }
  }
  &-link{
    font-family: var(--font-primary);
    font-size: 1.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration-line: underline;
    color: var(--color-link);
    word-break: break-word;

    @media screen and (max-width: 768px){
      font-size: 1.25rem;
      line-height: 1.5;
    }
  }
}

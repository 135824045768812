.section-default{
  &_container{
  }
  &_content{
    position: relative;
    padding: 8rem;

    @media screen and (max-width: 1024px){
      padding: 5rem;
    }
    @media screen and (max-width: 768px){
      padding: 5rem 2rem;
    }
  }
  &_description{
    font-weight: 400;
    margin-bottom: 2.5rem;
    line-height: 135%;
    a{
      color: currentColor;
    }
  }
}

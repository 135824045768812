.footer{
  padding: 3.75rem 6.5rem;

  @media screen and ( max-width: 1024px){
    padding: 3rem 4rem;
  }
  @media screen and ( max-width: 768px){
    padding: 2rem 2rem;
  }

  @media screen and ( max-width: 480px){
    padding: 2rem 1.25rem;
  }
  &-nav{
    &-list{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.5rem;

      @media screen and ( max-width: 480px){
        gap: 0.5rem;
      }
    }
    &-item{
      border-radius: 3.125rem;
      overflow: hidden;
      a{
        text-transform: uppercase;
        font-family: var(--font-secondary);
        font-size: 1.6875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        letter-spacing: -0.01856rem;
        padding: 0.175rem 0.9375rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transform: scale(1);
        text-decoration: none;
        transition: 0.5s ease;
        white-space: nowrap;

        @media screen and ( max-width: 1170px){
          font-size: 1.25rem;
        }
        @media screen and ( max-width: 768px){
          font-size: 1rem;
        }
        @media screen and ( max-width: 480px){
          font-size: 0.85rem;
        }
      }
      &.active{
        color: var(--color-black);
        a{
          transform: scale(0.8);
        }
      }
      &:hover{
        color: var(--color-black);
        a{
          transform: scale(0.8);
        }
      }
    }
  }

}

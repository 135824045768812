:root {
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-primary: #FF9B41;
  --color-secondary: #45B348;
  --color-third: #0E3692;
  --color-fourth: #D14988;
  --color-fifth: #09C2F4;
  --color-sixth: #EC008C;
  --color-seventh: #E52C1A;
  --color-eighth: #469CDE;
  --color-ninth: #FFEB3D;
  --color-tenth: #41321C;
  --color-eleventh: #505497;
  --color-twelfth: #D82E8A;

  --font-primary: "Vollkorn", sans-serif;
  --font-secondary: "Duplet Rounded", sans-serif;
  --color-link: #0E3692;
}

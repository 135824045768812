@font-face {
  font-family: 'Vollkorn';
  src: url('../assets/fonts/Vollkorn/Vollkorn-Medium.eot');
  src: url('../assets/fonts/Vollkorn/Vollkorn-Medium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Vollkorn/Vollkorn-Medium.otf') format('otf'),
  url('../assets/fonts/Vollkorn/Vollkorn-Medium.svg#Vollkorn-Medium') format('svg'),
  url('../assets/fonts/Vollkorn/Vollkorn-Medium.woff') format('woff'),
  url('../assets/fonts/Vollkorn/Vollkorn-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Vollkorn';
  src: url('../assets/fonts/Vollkorn/Vollkorn-Regular.eot');
  src: url('../assets/fonts/Vollkorn/Vollkorn-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Vollkorn/Vollkorn-Regular.woff2') format('woff2'),
  url('../assets/fonts/Vollkorn/Vollkorn-Regular.woff') format('woff'),
  url('../assets/fonts/Vollkorn/Vollkorn-Regular.ttf') format('truetype'),
  url('../assets/fonts/Vollkorn/Vollkorn-Regular.svg#Vollkorn-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Vollkorn';
  src: url('../assets/fonts/Vollkorn/Vollkorn-SemiBold.eot');
  src: url('../assets/fonts/Vollkorn/Vollkorn-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/Vollkorn/Vollkorn-SemiBold.woff2') format('woff2'),
  url('../assets/fonts/Vollkorn/Vollkorn-SemiBold.woff') format('woff'),
  url('../assets/fonts/Vollkorn/Vollkorn-SemiBold.ttf') format('truetype'),
  url('../assets/fonts/Vollkorn/Vollkorn-SemiBold.svg#Vollkorn-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Duplet Rounded';
  src: url('../assets/fonts/DupletRounded/DupletRounded-Light.eot');
  src: url('../assets/fonts/DupletRounded/DupletRounded-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/DupletRounded/DupletRounded-Light.woff2') format('woff2'),
  url('../assets/fonts/DupletRounded/DupletRounded-Light.woff') format('woff'),
  url('../assets/fonts/DupletRounded/DupletRounded-Light.ttf') format('truetype'),
  url('../assets/fonts/DupletRounded/DupletRounded-Light.svg#DupletRounded-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Duplet Rounded';
  src: url('../assets/fonts/DupletRounded/DupletRounded-Regular.eot');
  src: url('../assets/fonts/DupletRounded/DupletRounded-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/DupletRounded/DupletRounded-Regular.woff2') format('woff2'),
  url('../assets/fonts/DupletRounded/DupletRounded-Regular.woff') format('woff'),
  url('../assets/fonts/DupletRounded/DupletRounded-Regular.ttf') format('truetype'),
  url('../assets/fonts/DupletRounded/DupletRounded-Regular.svg#DupletRounded-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Duplet Rounded';
  src: url('../assets/fonts/DupletRounded/DupletRounded-Semibold.eot');
  src: url('../assets/fonts/DupletRounded/DupletRounded-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/DupletRounded/DupletRounded-Semibold.woff2') format('woff2'),
  url('../assets/fonts/DupletRounded/DupletRounded-Semibold.woff') format('woff'),
  url('../assets/fonts/DupletRounded/DupletRounded-Semibold.ttf') format('truetype'),
  url('../assets/fonts/DupletRounded/DupletRounded-Semibold.svg#DupletRounded-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


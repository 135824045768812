.video{
  &_player{
    position:relative;
    &.is-playing{
      z-index: 22;
    }
    video{
      display:block;
    }
  }
  &_poster{
    position:absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    //pointer-events: none;
    transition: 0.5s ease;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .is-playing &{
      height: calc(100% - 6rem);
      opacity: 0;
    }
    &:hover{
      cursor: pointer;
    }
  }
  &_button{
    position:absolute;
    left: 50%;
    top: 50%;
    width: 10rem;
    height: 10rem;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    font-size: inherit;
    transform: translate(-50%, -50%) scale(1);
    transition: 0.5s ease;

    @media screen and (max-width:768px){
      width: 5rem;
      height: 5rem;
    }

    .is-playing &{
      opacity: 0;
    }
    .is-playing:hover &{
      opacity: 0;

      &:hover{
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
      }
    }
    &:hover{
      transform: translate(-50%, -50%) scale(0.9);
    }
  }
}

.section-popup_slider{
  &_bg{
    width: 100vw;
    //position: absolute;
    top: 0;
    left: 0;
    height: calc(70vh + 200px);
    max-height: calc(42.1875rem + 200px);
    min-height: calc(20rem + 200px);

    @media screen and (max-width: 768px){
      height: calc(50vh + 200px);
      max-height: calc(32.1875rem + 200px);
      min-height: calc(10rem + 200px);
    }
    @media screen and (max-width: 420px){
      height: calc(30vh + 200px);
      max-height: calc(22.1875rem + 200px);
      min-height: calc(10rem + 200px);
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &_row{
    display:flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 4.69rem;
    .muur-h2__wrapper + & {
      margin-top: 3.12rem;
    }
    @media screen and (max-width: 1024px){
      flex-direction: column;
    }
  }
  &_preview{
    height: 70vh;
    max-height: 42.1875rem;
    min-height: 20rem;
    display: flex;
    align-items: flex-end;
    position: relative;
    @media screen and (max-width: 768px){
      height: 50vh;
      max-height: 32.1875rem;
      min-height: 10rem;
    }
    @media screen and (max-width: 420px){
      height: 30vh;
      max-height: 22.1875rem;
      min-height: 10rem;
    }
    .muur-h2__wrapper{
      margin-bottom: -3rem;
      z-index: 1;
      position: relative;

      @media screen and (max-width: 1024px){
        margin-bottom: -2.5rem;
      }

      @media screen and (max-width: 768px){
        margin-bottom: -2rem;
      }
      @media screen and (max-width: 420px){
        margin-bottom: -1.5rem;
      }
    }
  }
  &_h2__wrapper{
    display: block;
    margin:{
      left: 4.62rem;
      right: 4.62rem;
    }

    @media screen and (max-width: 768px){
      margin:{
        left: 2rem;
        right: 0;
      }
    }
    @media screen and (max-width: 420px){
      margin:{
        left: 1rem;
        right: 0;
      }
    }
  }
  &_content{
    position: relative;
    padding: 8rem 4.37rem 8rem 0;

    @media screen and (max-width: 1024px){
      padding: 5rem 4.37rem 5rem 0;
    }
    @media screen and (max-width: 768px){
      padding: 5rem 3rem 5rem 0;
    }
    .muur-h2__wrapper{
      margin-top: -11.35rem;
      z-index: 1;
      position: relative;
      @media screen and (max-width: 1024px){
        margin-top: -7.35rem;
      }

      @media screen and (max-width: 768px){
        margin-top: -6.75rem;
      }

      @media screen and (max-width: 420px){
        margin-top: -6.5rem;
      }
    }
  }
  &_description{
    padding:{
      left: 8rem;
    }

    @media screen and (max-width: 1024px){
      padding:{
        left: 4rem;
      }
    }

    @media screen and (max-width: 768px){
      padding:{
        left: 2rem;
      }
    }
  }
  &_button{
    align-self: flex-end;
  }
}

.popup{
  &-wrapper{
    position: fixed;
    top: 0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: 111;
  }
  &-bg{
    background: #000;
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &-container{
    background: var(--color-white);
    color: var(--color-black);
    position: relative;
    z-index: 1;
    height: 100%;
  }
  &-body{
    padding: 5rem 5.6rem 6rem;
    overflow-y: auto;
    max-height: 100%;

    @media screen and (max-width: 1024px){
      padding: 5rem 5.6rem 5rem;
    }

    @media screen and (max-width: 768px){
      padding: 5rem 1.5rem 5rem;
    }
  }
  &-close{
    background: transparent;
    border: 0;
    box-shadow: none;
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 2;
    transition: 0.5s ease;
    opacity: 0.75;
    &:hover{
      cursor: pointer;
      opacity: 1;
      transform: rotate(180deg);
    }
  }
}

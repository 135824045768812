.nav{
  margin-left: -5rem;
  transition: 0.6s linear;
  .is-sticky &{
    margin-left: 0;
  }
  @media screen and (max-width: 991px) {
    //display: none;
    opacity: 0;
    margin-left: 0;
    margin-top: 3rem;
    margin-bottom: 2rem;
    transition: 0.5s ease;
    .is-open-nav &{
      opacity: 1;
      margin-top: 0;
      //display: block;
    }
  }
  &-list{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5625rem;
    @media screen and (max-width: 991px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  &-item{
    border-radius: 3.125rem;
    overflow: hidden;
    display: block;
    &_mobile{
      display: none;
      @media screen and ( max-width: 991px){
        display: block;
      }
    }
    span{
      font-family: var(--font-secondary);
      font-size: 1.6875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 110%;
      letter-spacing: -0.01856rem;
      padding: 0.175rem 0.9375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transform: scale(1);
      text-decoration: none;
      transition: 0.5s ease;
      white-space: nowrap;

      @media screen and ( max-width: 1370px) and (min-width: 1111px){
        font-size: 1.25rem;
      }

      @media screen and ( max-width: 1110px) and (min-width: 992px){
        font-size: 1rem;
      }
    }
    &.is-active{
      color: var(--color-black);
      span{
        transform: scale(0.8);
      }
    }
    &:hover{
      color: var(--color-black);
      cursor: pointer;
      span{
        transform: scale(0.8);
      }
    }
  }
}

.infographic{
  &-wrapper{
    padding: 3.9rem 5rem 0;
    @media screen and (max-width: 1024px){
      padding: 3.9rem 4rem 0;
    }
    @media screen and (max-width: 768px){
      padding: 3.9rem 2rem 0;
    }
  }
  &-image,
  &-vector{
    margin-bottom: 6.35rem;
    @media screen and (max-width: 1024px){
      margin-bottom: 5rem;
    }
    @media screen and (max-width: 768px){
      margin-bottom: 3rem;
    }
    svg{
      height: auto;
      width: 100%;
    }
  }
  &-list{
    display: grid;
    gap: 0.91rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    @media screen and (max-width: 1024px){
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 700px){
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &-description{
    font-family: var(--font-secondary);
    font-size: 2.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 2.44rem;

    @media screen and (max-width: 1024px){
      font-size: 1.75rem;
    }
    @media screen and (max-width: 768px){
      font-size: 1.5rem;
    }
  }
  &-item{
    width: 100%;
    cursor: pointer;
    &_preview{
      width: 100%;
      height: 100%;
      position: relative;
      filter: brightness(1);
      transition: 0.5s ease;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    &_count{
      width: 2.16525rem;
      height: 2.16344rem;
      background: var(--color-seventh);
      color: var(--color-white);
      font-family: var(--font-secondary);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.36969rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      z-index: 1;
      position: absolute;
      top: 0.9rem;
      right: 0.9rem;
      border-radius: 50%;

      @media screen and (max-width: 768px){
        transform: scale(0.7);
        transform-origin: top right;
        top: 0.5rem;
        right: 0.5rem;
      }
    }
    &_details{
      z-index: 12;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: visibility 0s ease, top 0.5s ease, left 0.5s ease, opacity 0.5s ease;
      max-width: 70vw;
      height: max-content;
      width: 35rem;
      background: var(--color-white);
      color: var(--color-black);
      border: 1px solid #000;
      bottom: 50%;
      left: 50%;
      box-shadow: 0 0 10rem 0 #000;

      @media screen and (max-width: 768px){
        max-width: 90vw;
        left: 50% !important;
        transform: translateX(-50%)
      }
      &__preview{
        width: 100%;
        height: 18rem;
        max-height: 33vw;
        z-index: 1;
        position: relative;
        @media screen and (max-width: 768px){
          max-height: 45vw;
        }
        img{
          width: 100%;
          min-height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &__content{
        padding: 2rem 2rem 3rem;
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        overflow-y: auto;
        max-height: calc(70vh - 18rem);
        z-index: 1;
        position: relative;
        background: var(--color-white);

        @media screen and (max-width: 1024px){
          gap: 1.5rem;
          padding: 1.5rem 1.5rem 1.5rem;
        }
        @media screen and (max-width: 768px){
          gap: 1rem;
          padding: 1rem;
        }
      }
      &_accordion{
        width: 100%;
        .accordion-item{
          margin-left: 2.5rem;
          .accordion-item_header:before {
            width: 1.3rem;
            height: 1.7rem;
          }
          ul{
            margin-left: 1rem;
          }
        }
        .muur-description{
          font-weight: 400;
          font-size: 1.25rem;
          flex-grow: 1;

          @media screen and (max-width: 768px){
            font-size: 1rem;
          }
        }
      }
      &__icon{
        max-width: 14.8125rem;
        width: 28%;
        min-width: 28%;
        //position: sticky;
        //top: 2rem;
      }
      &__text{
        font-weight: 400;
        font-size: 1.25rem;
        width: 67%;
        flex-grow: 1;

        @media screen and (max-width: 768px){
          font-size: 1rem;
        }
      }
      &__close{
        @media (hover: hover) {
          display: none;
        }
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        border-radius: 50%;
        border: 0;
        padding: 0;
        margin:0;
        box-shadow: none;
        z-index: 2;
        transition: 0.5s ease;
        opacity: 0.75;
        width: 2rem;
        height: 2rem;
        background: transparent;
        &:hover{
          cursor: pointer;
          opacity: 1;
          transform: rotate(180deg);
        }
      }
    }
    &.is-hover{
      z-index: 10;
    }
    &.is-hover &_details{
      visibility: visible;
      opacity: 1;
      transition-delay: 0.3s;
    }

    &.is-hover &_preview{
      filter: brightness(0.5);
    }
  }
}

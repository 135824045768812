.wieInfographic{
  &-wrapper{
    position:relative;
  }
  &-actionsSvg{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:block;
    &.is-hover{
    }
    &_item{
      transition: 0.3s ease;
      ellipse {
        transition: 0.3s ease;
      }
      &:hover ellipse,
      &.is-active ellipse{
        fill: var(--color-secondary);
      }

      &:hover,
      &.is-active{
        opacity: 1;
        cursor: pointer;
      }
      &.is-disabled{
        opacity: 0.5;
      }
    }
  }
}

.section-slider_slide{
  margin: 0 auto;
  min-height: 80vh;
  &_bg{
    width: 100%;
    border-radius: 1.25rem;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &_video{
    width: 100%;
    border-radius: 1.25rem;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center;
    }
  }
  &_row{
    display:flex;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
    max-width: 94%;
    .muur-h3__wrapper + & {
      margin-top: 3.12rem;
    }
    @media screen and (max-width: 1024px){
      flex-direction: column;
    }
  }
  &_preview{
    padding-top: 45.5%;
    display: flex;
    align-items: flex-end;
    position: relative;
    &_video {
      padding-top: 56%;
      & + .section-slider_slide_content .muur-h3__wrapper {
          margin-top: -6rem;

        @media screen and (max-width: 1024px){
          margin-top: -3rem;
        }
        @media screen and (max-width: 768px){
          margin-top: -3rem;
        }
        @media screen and (max-width: 420px){
          margin-top: -4rem;
          margin-left: 0;
        }
      }
    }
    .muur-h3__wrapper{
      margin-bottom: -3rem;
      z-index: 1;
      position: relative;
      margin-top: -100%;

      @media screen and (max-width: 1024px){
        margin-bottom: -2.5rem;
      }

      @media screen and (max-width: 768px){
        margin-bottom: -2rem;
      }
      @media screen and (max-width: 568px){
        font-size: 1.3rem;
        margin-left: 1rem;
      }
      @media screen and (max-width: 420px){
        margin-bottom: -1.5rem;
        margin-top: 0;
        font-size: 1rem;
        margin-left: 0.25rem;
      }
    }
  }
  &_h3__wrapper{
    display: block;
    margin:{
      left: 4.62rem;
      right: 4.62rem;
    }
    @media screen and (max-width: 1024px){
      margin-left: 2.62rem;
      margin-right: 2.62rem;
    }
    @media screen and (max-width: 768px){
      margin:{
        left: 2rem;
        right: 0;
      }
    }
    @media screen and (max-width: 420px){
      margin:{
        left: 1rem;
        right: 0;
      }
    }
  }
  &_content{
    position: relative;
    padding: 8rem 4.37rem 4rem 0;

    @media screen and (max-width: 1024px){
      padding: 5rem 4.37rem 4rem 0;
      .popup-body &{
        padding: 3rem 0;
      }
    }
    @media screen and (max-width: 768px){
      padding: 5rem 3rem 3rem 0;
      .popup-body &{
        padding: 3rem 0;
      }
    }
    .muur-h3__wrapper{
      margin-top: -11.35rem;
      z-index: 1;
      position: relative;
      @media screen and (max-width: 1024px){
        margin-top: -7.35rem;
      }

      @media screen and (max-width: 768px){
        margin-top: -6.75rem;
      }

      @media screen and (max-width: 420px){
        margin-top: -6.5rem;
      }
    }
  }
  &_description,
  &_link,
  &_accordion{
    line-height: 140%;
    font-weight: 500;
    padding:{
      left: 8rem;
    }

    @media screen and (max-width: 1024px){
      padding:{
        left: 4rem;
      }
      .popup-body &{
        padding-left: 2rem;
      }
    }

    @media screen and (max-width: 768px){
      padding:{
        left: 2rem;
      }
      .popup-body &{
        padding-left: 0;
      }
    }
  }
  &_button{
    align-self: flex-end;
  }
}

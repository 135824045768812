html,
body {
  min-height: 100vh;
  //scroll-behavior: smooth;
  @media screen and (width >= 1920px){
    font-size: .75vw;
  }
  @media screen and (width >= 2500px){
    font-size: 20px;
  }
}

body {
  color: var(--color-white);
  font-weight: 400;
  font-size: 16px;
  font-family: "Vollkorn", "Duplet Rounded", sans-serif;
  line-height: 1.35;
  font-style: normal;
  padding:0;
  margin:0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //cursor: url("../assets/cursor.svg"), default;
}

* {
  outline: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

html {
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #aaa;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #666;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--color-black);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  line-height: 1;
  margin: 0;
}

img, svg {
  max-width: 100%;
  display: block;
  max-height: 100%;
}

a {
  text-decoration: none;
  transition: none;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

ul,
ol {
  list-style-type: none;
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

button {
  outline: none;

  &:focus {
    outline: none;
  }
}

[class^="icon-"],
[class*=" icon-"] {
  position: relative;
  vertical-align: middle;
}

.wrapper {
  position: relative;
  min-height: 100%;
  overflow: hidden;
  background: var(--color-white);
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  max-width: 78rem;
  margin: {
    right: auto;
    left: auto;
  }
  padding: {
    right: 1.5rem;
    left: 1.5rem;
  }
}

.container-header {
  width: 100%;
  max-width: 83rem;
  margin: {
    right: auto;
    left: auto;
  }
  padding: {
    right: 1.5rem;
    left: 1.5rem;
  }
}

fieldset{
  border: none;
  padding: 0;
  margin: 0;
  box-shadow: none;
  outline: none;
}
.react-parallax-bgimage{
  object-position: top center;
  object-fit: cover;
}
@media screen and (width <= 992px) {
  .mobile-hidden {
    display: none !important;
  }
}

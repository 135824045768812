.button-popup{
  max-width:  20.4375rem;
  min-width:  20.4375rem;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: inherit;

  @media screen and (max-width: 420px){
    max-width:  100%;
    margin-left: 2rem;
    min-width:  12rem;
  }
  &_shortdescr{
    display:flex;
    align-items: center;
    margin-top: 1.9rem;
    gap: 1.28rem;
    text-align: left;

    @media screen and (max-width: 420px){
      margin-top: 1rem;
    }
  }
  &_title {
    font-family: var(--font-secondary);
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    transition: 0.5s ease;
    @media screen and (max-width: 768px){
      font-size: 1.25rem;
    }
    &__small{
      text-align: right;
      font-size: 1.125rem;
      line-height: 120%;
      @media screen and (max-width: 768px){
        font-size: 1rem;
      }
      & + .button-popup_icon{
        width: 9.21706rem;
        height: 3.84431rem;

        @media screen and (max-width: 420px){
          width: 6rem;
          height: 2.5rem;
        }
      }
    }
  }
  &_image{
    border-radius: 1.25rem;
    overflow: hidden;
    display: block;
    transition: 0.5s ease;
    &__wrapper{
      position: relative;
      display: block;
      .image_lottie{
        svg{
          width: auto !important;
        }
      }
      .button-popup_icon{
        margin-right: -1.5rem;
        margin-bottom: -1.5rem;
        position: absolute;
        bottom: 0;
        right: 0;
        @media screen and (max-width: 420px){
          margin-right: -1rem;
          margin-bottom: -1rem;
        }
      }
    }
  }
  &_icon{
    width: 12.4375rem;
    height: 5.1875rem;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    animation: 2s linear carrot;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    @media screen and (max-width: 420px){
      width: 6rem;
      height: 2.5rem;
    }
    svg{
      height: auto;
      width: 100%;
    }
  }

  &:hover{
    cursor: pointer;
  }
  &:hover &_image{
    transform: translateY(-0.5rem)
  }

  &:hover &_title{
    transform: translateY(-1rem)
  }

  &:hover &_title__small{
    transform: translateX(0.5rem)
  }
}

@keyframes carrot {
  0%{
    transform: translateX(0rem)
  }
  40%{
    transform: translateX(1rem)
  }
  90%{
    transform: translateX(-0.25rem)
  }
  100%{
    transform: translateX(0)
  }
}

.slick-slider{
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto auto auto;
  margin: 0 1rem;
  @media screen and (max-width: 768px){
    margin: 0;
  }
}
.slick-prev{
  grid-column: 1;
  grid-row: 1;
  right: 100%;
  margin-right: 1rem;
  background-image: url("../assets/icons/arrow-l.svg");
  @media screen and (max-width: 768px){
    margin-right: -4rem;
  }

  @media screen and (max-width: 560px){
    margin-right: -2.5rem;
  }
}
.slick-list{
  grid-column: 1/5;
  grid-row: 1;
  height: auto !important;
}
.slick-next{
  grid-column: 5;
  grid-row: 1;
  left: 100%;
  margin-left: 1rem;
  background-image: url("../assets/icons/arrow-r.svg");

  @media screen and (max-width: 768px){
    margin-left: -4rem;
  }
  @media screen and (max-width: 560px){
    margin-left: -2.5rem;
  }
}
.slick-slide {
  max-height: 0;
  &:hover{
    cursor: ew-resize;
  }
  &.slick-active {
    max-height: none;
  }
}
.slick-arrow{
  background-color: transparent;
  font-size: 0;
  padding: 0;
  border: 0;
  width: 4.37725rem;
  height: 1.68744rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  position: absolute;
  top: calc(45.5vw - 3rem);
  transition: 0.3s ease;
  z-index: 2;
  @media (hover: hover) {
    &:not(.slick-disabled):hover {
      opacity: 1;
      cursor: pointer;
    }
  }
  &.slick-disabled{
    opacity: 0.2;
  }
  @media screen and (max-width: 768px){
    top: calc(17.5vw);
    transform: translateY(-50%);
    width: 3rem;
    height: 1rem;
    opacity: 1;
    filter: drop-shadow(1px 2px 1px var(--color-black)) drop-shadow(-1px -1px 3px var(--color-primary));
    zoom: 1.2;
  }

  @media screen and (max-width: 560px){
    width: 2rem;
    height: 1rem;
  }
}
.slick-dots {
  display: flex !important;
  align-items: center;
  gap: 0.85rem;
  grid-column: 1/5;
  grid-row: 2;
  margin: 0 auto;
  padding: 0;
  li {
    padding: 0;
    margin:0;
    display: block;
    line-height: 0;
    button {
      background: var(--color-primary);
      font-size: 0;
      border: 0;
      padding: 0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: inline-block;
      opacity: 0.5;
      transition: 0.3s ease;
      margin: 0;

      @media screen and (max-width: 768px){
        width: 15px;
        height: 15px;
      }
      @media (hover: hover) {
        &:hover {
          cursor: pointer;
          opacity: 1;
        }
      }
    }

    &.slick-active button {
      opacity: 1;
    }
  }
}

.primary-bg{
  background-color: var(--color-primary);
}
.secondary-bg{
  background-color: var(--color-secondary);
}
.third-bg{
  background-color: var(--color-third);
}
.fourth-bg{
  background-color: var(--color-fourth);
}
.fifth-bg{
  background-color: var(--color-fifth);
}
.sixth-bg{
  background-color: var(--color-sixth);
}
.seventh-bg{
  background-color: var(--color-seventh);
}
.eighth-bg{
  background-color: var(--color-eighth);
}
.ninth-bg{
  background-color: var(--color-ninth);
}
.tenth-bg{
  background-color: var(--color-tenth);
}
.eleventh-bg{
  background-color: var(--color-eleventh);
}
.black-bg{
  background-color: var(--color-black);
}
.white-bg{
  background-color: var(--color-white);
}

//======================
.primary-fill{
  fill: var(--color-primary);
  svg{
    fill: var(--color-primary);
  }
}
.secondary-fill{
  fill: var(--color-secondary);
  svg{
    fill: var(--color-secondary);
  }
}
.third-fill{
  fill: var(--color-third);
  svg{
    fill: var(--color-third);
  }
}
.fourth-fill{
  fill: var(--color-fourth);
  svg{
    fill: var(--color-fourth);
  }
}
.fifth-fill{
  fill: var(--color-fifth);
  svg{
    fill: var(--color-fifth);
  }
}
.sixth-fill{
  fill: var(--color-sixth);
  svg{
    fill: var(--color-sixth);
  }
}
.seventh-fill{
  fill: var(--color-seventh);
  svg{
    fill: var(--color-seventh);
  }
}
.eighth-fill{
  fill: var(--color-eighth);
  svg{
    fill: var(--color-eighth);
  }
}
.ninth-fill{
  fill: var(--color-ninth);
  svg{
    fill: var(--color-ninth);
  }
}
.tenth-fill{
  fill: var(--color-tenth);
  svg{
    fill: var(--color-tenth);
  }
}
.black-fill{
  fill: var(--color-black);
  svg{
    fill: var(--color-black);
  }
}
.white-fill{
  fill: var(--color-white);
  svg{
    fill: var(--color-white);
  }
}
.eleventh-fill {
  fill: var(--color-eleventh);

  svg {
    fill: var(--color-eleventh);
  }
}

//============================
.primary-color{
  color: var(--color-primary);
}
.secondary-color{
  color: var(--color-secondary);
}
.third-color{
  color: var(--color-third);
}
.fourth-color{
  color: var(--color-fourth);
}
.fifth-color{
  color: var(--color-fifth);
}
.sixth-color{
  color: var(--color-sixth);
}
.seventh-color{
  color: var(--color-seventh);
}
.eighth-color{
  color: var(--color-eighth);
}
.ninth-color{
  color: var(--color-ninth);
}
.tenth-color{
  color: var(--color-tenth);
}
.black-color{
  color: var(--color-black);
}
.white-color{
  color: var(--color-white);
}
.eleventh-color{
  color: var(--color-eleventh);
}

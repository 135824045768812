.header{
  padding: 2.19rem 2.19rem 3rem;
  background: var(--color-white);
  position:sticky;
  top: 0;
  z-index: 100;
  transition: 0.4s linear;
  &.is-sticky{
    padding: 1.35rem 2.19rem;
  }
  @media screen and (max-width: 991px) {
    padding: 0.75rem 1.5rem;
    &.is-sticky{
      padding: 0.75rem 1.5rem;
    }
  }
  &-row{
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
  &-not-found &-row{
    justify-content: flex-end;
  }

  &-not-found &-row &-logo_mini{
    display: block;
    max-width: 30vw;
  }
  &-logo{
    &_wrp{
      display: flex;
      align-items: center;
      gap: 1.5rem;
      max-width: 20%;
      @media screen and (max-width: 991px) {
        max-width: 10rem
      }
    }
    &_image{
      max-width: 8.9375rem;
      width: 30%;
      transition: 0.4s linear;
      .is-sticky &{
        max-width: 2.84369rem;
      }
      @media screen and (max-width: 991px) {
        max-width: 1.73419rem;
        .is-sticky & {
          max-width: 1.73419rem;
        }
      }
    }
    &_text{
      max-width: 18.9375rem;
      width: 65%;
      margin-bottom: 2rem;
      margin-top: 0.7rem;
      transition: 0.4s linear;
      .is-sticky &{
        max-width: 9.75rem;
        margin-bottom: 0;
        margin-top: -1rem;
      }
      @media screen and (max-width: 991px) {
        max-width: 5.9375rem;
        margin-bottom: 0;
        margin-top: -0.25rem;
        .is-sticky & {
          max-width: 5.5rem;
          margin-top: -0.25rem;
        }
      }
    }
    &_mini{
      max-width: 10.875rem;
      max-height: 3rem;
      margin-bottom: 1rem;
      .is-sticky &{
        margin-bottom: 0.5rem;
      }
      @media screen and (max-width: 991px) {
        display: none;
      }
      img{
        width: 100%;
      }
    }
  }
  &-col_right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content:space-between;
    @media screen and (max-width: 991px) {
      justify-content:flex-start;
      align-items: flex-start;
      overflow: hidden;
      max-height: 0;
      width: 100%;
      position: absolute;
      top:5rem;
      left:0;
      background: #fff;
      padding: 0 2rem 0 3rem;
      transition: 0.5s ease;
      .is-open-nav &{
        margin-top: 1.5rem;
        max-height: calc(100vh - 6rem);
        overflow-y: auto;
        top:3rem;
        padding: 2.5rem 2rem 1rem 3rem;
      }
    }
  }

  .button-nav{
    width: 1.7rem;
    height: 2.34194rem;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    align-self: center;
    svg{
      width: 100%;
    }
    @media screen and (min-width: 992px) {
      display: none;
    }
  }
}

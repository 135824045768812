.section-not-found{
  &_container{
  }
  &_content{
    position: relative;
    padding: 0 2rem 3rem;
    text-align: center;
    &_row{
      display: flex;
      align-items: flex-end;
      justify-content:center;
    }
  }
  &_404{
    font-size: 15rem;
    font-family: var(--font-secondary);
    font-weight: 700;
    line-height:1;
    @media screen and (max-width: 768px) {
      font-size: 8rem;
    }
  }
  &_title{
    font-size: 1.675rem;
    font-family: var(--font-secondary);
    font-weight: 700;
    margin: 1rem 0 2rem;
    color: var(--color-black);
  }
  .image_lottie{
    margin-right: -2rem;
    margin-left: -1rem;
    max-width: 17rem;
    @media screen and (max-width: 768px) {
      max-width: 10rem;
    }
  }
  &_btn{
    color: var(--color-black);
    display: flex;
    align-items: center;
    font-family: var(--font-secondary);
    font-size: 1.25rem;
    gap: 1rem;
    width: fit-content;
    margin: auto;
    transition: 0.3s ease;
    .button-popup_icon{
      width: 5rem;
    }
    &:hover{
      color: var(--color-primary);
    }
  }

}
